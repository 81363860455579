import React, { useState, useEffect } from "react";
import ContentSvg from "../assets/images/svg/content-svg.svg";
import YoutubeSvg from "../assets/images/svg/youtube-svg.svg";
import VimeoSvg from "../assets/images/svg/vimeo-svg.svg";
import SoundCloudSvg from "../assets/images/svg/sound-cloud.svg";
import GallerySvg from "../assets/images/svg/gallery-svg.svg";
import YouTubeModal from "./YouTubeVideo";
import VimeoModal from "./VimeoModal";
import SoundCloudModal from "./SoundCloudVideo";
import Galleryimg1 from "../assets/images/gallery/gallery-img1.jpg";
import Galleryimg2 from "../assets/images/gallery/gallery-img2.jpg";
import Galleryimg3 from "../assets/images/gallery/gallery-img3.jpg";
import Galleryimg4 from "../assets/images/gallery/gallery-img4.jpg";
import Galleryimg5 from "../assets/images/gallery/gallery-img5.jpg";
import Galleryimg6 from "../assets/images/gallery/gallery-img6.jpg";
import Galleryimg7 from "../assets/images/gallery/gallery-img7.jpg";
import Galleryimg8 from "../assets/images/gallery/gallery-img8.jpg";
import DueRoute from "../assets/images/gallery/due-route.png";
import DistanceSales from "../assets/images/gallery/distance-sales.png";
import EspanzioneTV from "../assets/images/gallery/espanzione-tv.png";
import QRPayBiz from "../assets/images/gallery/qrpaybiz.png";
import MAE from "../assets/images/gallery/mae.jpg";
import Unifi from "../assets/images/gallery/2unifi-nbh.jpg";
import Elevance from "../assets/images/gallery/elevance-health.jpg";
import Bmarken from "../assets/images/gallery/bmarken.png";

import Popup from "./PopUp";
import { Link } from "react-router-dom";

const Portfolio = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopupOpen = (e) => {
    e.preventDefault();
    setIsPopupVisible(true);
  };

  const handlePopupClose = (e) => {
    e.preventDefault();
    setIsPopupVisible(false);
  };

  // Vimeo Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Youtube Modal
  const [isModalOpenYoutube, setisModalOpenYoutube] = useState(false);

  const handleOpenModalYoutube = () => {
    setisModalOpenYoutube(true);
  };

  const handleCloseModalYoutube = () => {
    setisModalOpenYoutube(false);
  };

  // Soundcloud Modal
  const [isModalOpenSound, setIsModalOpenSound] = useState(false);

  const handleOpenModalSound = () => {
    setIsModalOpenSound(true);
  };

  const handleCloseModalSound = () => {
    setIsModalOpenSound(false);
  };

  useEffect(() => {
    // Function to initialize fancybox for the gallery

    // Event listener to toggle 'full' class on image click
    const handleImageClick = (event) => {
      event.target.classList.toggle("full");
    };

    // Attach click event to gallery images
    const galleryLinks = document.querySelectorAll(".gallery-link img");
    galleryLinks.forEach((link) => {
      link.addEventListener("click", handleImageClick);
    });

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      galleryLinks.forEach((link) => {
        link.removeEventListener("click", handleImageClick);
      });
    };
  }, []);
  return (
    <>
      {/* <!-- ====================================== Section Portfolio ===================================== --> */}
      <section className="portfolio-section" id="portfolio">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Portfolio.
          </h2>
          <div className="line"></div>
        </div>
        <div className="portfolios-group-main">
          <div>
            <a href="https://www.dueruote.it/" rel="noreferrer" target="_blank">
              <div className="image-container popup-btn zoom_in">
                <img src={DueRoute} alt="DueRoute.it" />
                <div className="overlay">
                  <img
                    src={ContentSvg}
                    alt="vimeo-svg"
                    className="vimeo-icon"
                  />
                  <p className="overlay-text">Due Route</p>
                </div>
              </div>
            </a>
            <p className="Corporate zoom_in">Due Route</p>
            <p className="Corporate-sub zoom_in">
              Internal Inventory Management Tool
            </p>
            <div className="second-row-portfolio youtube zoom_in">
              <div>
                <div
                  className="image-container"
                  onClick={handleOpenModalYoutube}
                >
                  <img src={DistanceSales} alt="youtube-video-img" />
                  <div className="overlay">
                    <img
                      src={YoutubeSvg}
                      alt="youtube-svg"
                      className="vimeo-icon"
                    />
                    <p className="overlay-text">Fashion & Retail App</p>
                  </div>
                </div>
                <YouTubeModal
                  isOpen={isModalOpenYoutube}
                  onRequestClose={handleCloseModalYoutube}
                />
              </div>
              <p className="Corporate">Distance Sales</p>
              <p className="Corporate-sub">Fashion & Retail App Development</p>
            </div>
          </div>
          <div>
            <div>
              <div
                className="image-container zoom_in"
                id="vimeo"
                onClick={handleOpenModal}
              >
                <img src={EspanzioneTV} alt="vimeo-video-img" />
                <div className="overlay">
                  <img src={VimeoSvg} alt="vimeo-svg" className="vimeo-icon" />
                  <p className="overlay-text">Espanzione TV</p>
                </div>
              </div>
              <VimeoModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
              />
            </div>
            <p className="Corporate zoom_in">Espanzione TV</p>
            <p className="Corporate-sub zoom_in">News/Media App Development</p>
            <div>
              <div
                className="second-row-portfolio soundcloud zoom_in"
                onClick={handleOpenModalSound}
              >
                <div className="image-container">
                  <img src={QRPayBiz} alt="sound-cloud-img" />
                  <div className="overlay">
                    <img
                      src={SoundCloudSvg}
                      alt="sound-cloud"
                      className="vimeo-icon"
                    />
                    <p className="overlay-text">QRPayBiz</p>
                  </div>
                </div>
                <p className="Corporate">QRPayBiz</p>
                <p className="Corporate-sub">
                  Banking App for Merchants to collect payments
                </p>
              </div>
              <SoundCloudModal
                isOpen={isModalOpenSound}
                onRequestClose={handleCloseModalSound}
              />
            </div>
          </div>
          <div className="gallery-bank-section">
            <div className="zoom_in">
              <div className="image-container">
                <a href="#popup2">
                  <img src={MAE} alt="gallery-img" />
                  <div className="overlay">
                    <img
                      src={GallerySvg}
                      alt="gallery-svg"
                      className="vimeo-icon"
                    />
                    <p className="overlay-text">MAE</p>
                  </div>
                </a>
              </div>
              <p className="Corporate">Banking App</p>
              <p className="Corporate-sub">
                Banking App with multiple services
              </p>
            </div>
            <div
              className="second-row-portfolio popup-btn zoom_in"
              onClick={handlePopupOpen}
            >
              <div className="image-container">
                <img src={Unifi} alt="content-img2" />
                <div className="overlay">
                  <img
                    src={ContentSvg}
                    alt="content-svg"
                    className="vimeo-icon"
                  />
                  <p className="overlay-text">2unifi</p>
                </div>
              </div>
              <p className="Corporate">2Unifi</p>
              <p className="Corporate-sub">B2B Banking App</p>
            </div>
            <div
              className="second-row-portfolio popup-btn zoom_in"
              onClick={handlePopupOpen}
            >
              <div className="image-container">
                <img src={Elevance} alt="content-img2" />
                <div className="overlay">
                  <img
                    src={ContentSvg}
                    alt="content-svg"
                    className="vimeo-icon"
                  />
                  <p className="overlay-text">Sydney Health</p>
                </div>
              </div>
              <p className="Corporate">Sydney Health - Elevance</p>
              <p className="Corporate-sub">Healthcare App</p>
            </div>
            <div
              className="second-row-portfolio popup-btn zoom_in"
              onClick={handlePopupOpen}
            >
              <div className="image-container">
                <img src={Bmarken} alt="content-img2" />
                <div className="overlay">
                  <img
                    src={ContentSvg}
                    alt="content-svg"
                    className="vimeo-icon"
                  />
                  <p className="overlay-text">Blockchain Marketing App</p>
                </div>
              </div>
              <p className="Corporate">BMarken - LiberActa</p>
              <p className="Corporate-sub">Blockchain Marketing</p>
            </div>
          </div>
        </div>
        <div className="wrapper view-all-btn zoom_in">
          <Link className="btn-hover" to="#">
            View All
          </Link>
        </div>
      </section>
      <Popup isVisible={isPopupVisible} onClose={handlePopupClose} />
      {/* <!-- ====================================== Section Portfolio End ===================================== --> */}
      <div id="popup2" className="popup-container popup-style">
        <div className="popup-content popup-content-gallery">
          <a href="#pop" className="close">
            &times;
          </a>

          <main className="main">
            <h2 className="mobile_app">Gallery</h2>

            <div className="container">
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg1}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg1} alt="gallery-img1" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg2}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg2} alt="gallery-img2" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg3}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg3} alt="gallery-img3" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg4}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg4} alt="gallery-img4" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg5}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg5} alt="gallery-img5" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg6}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg6} alt="gallery-img6" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg7}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg7} alt="gallery-img7" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg8}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg8} alt="gallery-img8" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg3}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg3} alt="gallery-img3" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg1}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg1} alt="gallery-img1" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg2}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg2} alt="gallery-img2" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg3}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg3} alt="gallery-img3" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg4}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg4} alt="gallery-img4" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg5}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg5} alt="gallery-img5" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg1}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg1} alt="gallery-img1" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg2}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg2} alt="gallery-img2" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg3}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg3} alt="gallery-img3" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg4}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg4} alt="gallery-img4" />
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <Link
                    to={Galleryimg5}
                    data-fancybox="gallery"
                    data-caption="Caption Images 1"
                  >
                    <img src={Galleryimg5} alt="gallery-img5" />
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default Portfolio;
