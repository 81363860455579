import React, { useEffect, useRef } from "react";
import FigmaImg from "../assets/images/figma-img.png";
import PhotoShopImg from "../assets/images/photoshop-img.png";
import AdobeImg from "../assets/images/adobe-xd-img.png";
import SketchImg from "../assets/images/sktech-img.png";

const Resume = () => {
  const colors = [
    "#BCE70C",
    "#FF759C",
    "#00CC97",
    "#FFDB59",
    "#6F39FD",
    "#FF7D61",
  ];
  const progressRef = useRef(null);
  const hasAnimated = useRef(false); // Track if the animation has already run

  useEffect(() => {
    const progressSection = progressRef.current;
    const items = progressSection.querySelectorAll(".progress-item");
    const observerOptions = { threshold: 0.1 };

    function handleIntersection(entries, observer) {
      if (entries[0].isIntersecting && !hasAnimated.current) {
        items.forEach((item, index) => {
          let num = parseInt(item.dataset.num);
          let count = 0;
          let color = colors[index % colors.length];
          let interval = setInterval(() => {
            if (count === num) {
              clearInterval(interval);
            } else {
              count++;
              item.style.background = `conic-gradient(${color} ${count}%, #80808047 0deg)`;
              item.setAttribute("data-value", `${count}%`);
              item.innerHTML = `${count}%`;
            }
          }, 15);
        });
        observer.unobserve(progressSection);
        hasAnimated.current = true; // Mark that the animation has run
      }
    }

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );
    observer.observe(progressSection);

    return () => observer.disconnect();
  }, [colors]);

  return (
    <>
      {/* <!-- ====================================== Section Education Experience ===================================== --> */}
      <section className="education-experience" id="resume">
        <div className="row">
          <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">
                Education.
              </h2>
              <div className="line"></div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">
                  MSc in Safety Engineering - Transport, Logistics and
                  Production
                </p>
                <p className="cursus university">
                  University of Genova / 2017 - 2020
                </p>
                {/* <p className="cursus">
                  Eu nulla at mauris cursus consectetur posuere iaculis ipsum
                  neque. Morbi felis pellentesque ligula sed dictumst imperdiet
                  nunc vulputate.
                </p> */}
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">
                  Bachelor in Technology - Mechanical Engineering
                </p>
                <p className="cursus university">
                  SRM University / 2012 - 2016
                </p>
                {/* <p className="cursus">
                  Porttitor euismod at semper ut massa. Lorem varius magna
                  volutpat nunc. Et faucibus scelerisque donec augue eleifenda
                  morbi.
                </p> */}
              </div>
            </div>
            {/* <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Diploma in Computer</p>
                <p className="cursus university">
                  Cambridge University / 2016 - 2018
                </p>
                <p className="cursus">
                  Adipiscing sed magna tempus arcu morbi. Ipsum pellentesque
                  lorem suscipit in. Hendrerit rhoncus quis tempor urna pharetra
                  ut erat.
                </p>
              </div>
            </div> */}
          </div>
          <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">
                Experience.
              </h2>
              <div className="line"></div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">LEAD II - Software Engineering</p>
                <p className="cursus university">
                  UST Global / Oct 2023 - Current
                </p>
                {/* <p className="cursus">
                  Web application development using Angular and mobile
                  application development using React Native and TypeScript.
                  Added features in Find Care, Outreach Preferences and Claims
                  modules. Worked on Test automation using TypeScript, Cucumber
                  framework for Mobile. Wrote unit test cases using Jest,
                  React-test-renderer for mobile app.
                </p> */}
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Senior Software Development Engineer</p>
                <p className="cursus university">
                  Mobiquity / May 2023 - Sep 2023
                </p>
                {/* <p className="cursus">
                  Worked on developing mobile app using React Native, Redux,
                  TypeScript. Developed Animations using Lottie Animations and
                  Reanimated libraries. Worked with native modules for Fraud
                  Prevention and authentication using OAuth. Participated in
                  Sprint planning, Grooming, estimation of story points for user
                  stories and Demo calls with Clients. Display PDFs and
                  downloading files using Blobs.
                </p> */}
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Software Consultant</p>
                <p className="cursus university">
                  Optimum Solutions (S) Pte Ltd, Singapore / Jan 2022 - May 2023
                </p>
                {/* <p className="cursus">
                  Worked on developing mobile app using React Native,
                  TypeScript, Redux. Created UX flows, loading animations as per
                  business requirement. Secure2U was integrated to eliminate the
                  need for OTPs for secure authentication. Worked on features
                  like Google Maps, accessing GPS, QR Code scanner, Push
                  Notifications & PDF share. Used Redux & Redux Thunk as a
                  middleware to make state management Asynchronous.
                </p> */}
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Software Developer</p>
                <p className="cursus university">
                  Kineton / Apr 2021 - Jan 2022
                </p>
                {/* <p className="cursus">
                  Developed cross-platform application using React-Native,
                  JavaScript, Redux for both iOS, Android phones and tablets.
                  Added features like Video player, Live streaming video of HLS
                  format. • Native Apps for Android TV and tvOS, pairing with
                  other devices and Chrome cast from mobiles. Dynamic routing
                  using react-router-dom, creating customized UI’s following
                  Material design pattern. Created animations based on
                  CSS-classes, styled components, applying upto ES9 including
                  async/await. Tracked and resolved bugs/issues in JIRA.
                </p> */}
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Software Developer</p>
                <p className="cursus university">
                  DS Group SpA / Sep 2020 - Apr 2021
                </p>
                {/* <p className="cursus">
                  Exploited latest features from TypeScript 4.0 with Angular for
                  developing reusable components there by decreasing development
                  time by 30%. Integrated RxJS within application to handle
                  streams of data and propagation of change. Involved in
                  development of cross platform application using Flutter.
                  Utilized WebSockets for enabling co-browsing and peer-peer
                  connection using WebRTC signaling. Used Provider for state
                  management and permission handler for accessing device
                  components. Managed workflow in software Agile development
                  methodologies like Kanban and Scrum.
                </p> */}
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Business Analyst</p>
                <p className="cursus university">
                  Autorità di Sistema Portuale del Mar Ligure occidentale / Jun
                  2019 - Dec 2019
                </p>
                {/* <p className="cursus">
                  Implemented Business Process Re-Engineering (BPR) enforcing
                  radical changes within the organization to increasing eﬃciency
                  by 30%. Interviewed Process Owners and analyzed the average
                  activity volume & workload on resources in a year. Interviewed
                  Process Owners and analyzed the average activity volume &
                  workload on resources in a year. Developed Digital Twins
                  simulating business model making special functioning service
                  block and decision block. Discrete-Event modeling using Java
                  libraries in AnyLogic. Produced detailed reports on Resource
                  Saturation, optimized process times by 20% suggesting
                  redundant process elimination.
                </p> */}
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Jr Full Stack Developer</p>
                <p className="cursus university">
                  Colour Moon Technologies Pvt Ltd / Jul 2015 - Aug 2017
                </p>
                {/* <p className="cursus">
                  Delivered engaging and visually compelling web apps built with
                  React.js, JavaScript, HTML, CSS. User-friendly UI/UX with
                  cross browser compatibility and mobile responsiveness. Created
                  microservices and endpoints using Node.js to serve data to
                  Front End. Developed a Mobile app in React Native, JavaScript
                  to enable businesses manage loyalty marketing, powered by
                  blockchain technology BMarkEn. Handled API calls with AJAX and
                  processed JSON and XML data responses to format, validate and
                  display to the user. Retrieved data with Observables, Promise
                  in applications and designed RESTFUL API's for CRUD
                  operations.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Education Experience End ===================================== --> */}
      {/* <!-- ====================================== Section Coding Skill ===================================== --> */}
      <section className="coding-skill-section">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Coding Skills.
          </h2>
          <div className="line"></div>
        </div>
        <div id="progress" ref={progressRef}>
          <div data-num="92" data-value="JS" className="progress-item fade_up">
            sd
          </div>
          <div data-num="89" data-value="TS" className="progress-item fade_up">
            sd
          </div>
          <div data-num="75" data-value="Py" className="progress-item fade_up">
            sd
          </div>
          <div data-num="72" data-value="GO" className="progress-item fade_up">
            sd
          </div>
          {/* <div data-num="67" className="progress-item fade_up">
            sd
          </div> */}
          {/* <div data-num="83" className="progress-item fade_up">
            ds
          </div> */}
        </div>
      </section>
      {/* <!-- ====================================== Section Coding Skill End ===================================== --> */}
      {/* <!-- ====================================== Section Design Skill ===================================== --> */}
      <section className="design-skill-section">
        <div className="heading-container">
          <h2 className="section-heading-text design-skill-text fade_up">
            Design Skills.
          </h2>
          <div className="line"></div>
        </div>
        <div className="design-skill-sub-section">
          <div className="design-skills-img-main flip_up">
            <img src={FigmaImg} alt="figma-img" />
            <div className="skill-counter-main">
              <p>94%</p>
              <p>HTML, CSS</p>
            </div>
          </div>
          <div className="design-skills-img-main photoshop flip_up">
            <img src={PhotoShopImg} alt="photoshop-img" />
            <div className="skill-counter-main photoshop-text">
              <p>98%</p>
              <p>PHOTOSHOP</p>
            </div>
          </div>
          <div className="design-skills-img-main adobe-xd flip_up">
            <img src={AdobeImg} alt="adobe-xd-img" />
            <div className="skill-counter-main adobe-xd-text">
              <p>88%</p>
              <p>ADOBE XD</p>
            </div>
          </div>

          <div className="design-skills-img-main sketch flip_up">
            <img src={SketchImg} alt="sktech-img" />
            <div className="skill-counter-main sketch-text">
              <p>85%</p>
              <p>SKETCH</p>
            </div>
          </div>
          {/* <div className="design-skills-img-main invision flip_up">
            <img src={InvisionImg} alt="invision-img" />
            <div className="skill-counter-main invision-text">
              <p>89%</p>
              <p>INVISION</p>
            </div>
          </div> */}
        </div>
      </section>
      {/* <!-- ====================================== Section Coding Skill End ===================================== --> */}
      {/* <!-- ====================================== Section Award ===================================== --> */}
      {/* <section className="awards-section overflow-hidden">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Awards.
          </h2>
          <div className="line"></div>
        </div>
        <div className="row awards-row">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={WinnerAward}
                        alt="winner-award4"
                      />
                      <p className="award-yer">2025</p>
                    </div>
                    <p className="award-interior">Awwwards Interior</p>
                    <p className="award-winner-text">Winner</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Sit, perferendis!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={WinnerAward2}
                        alt="winner-award2"
                      />
                      <p className="award-yer">2024</p>
                    </div>
                    <p className="award-interior">The Webby Awards</p>
                    <p className="award-winner-text">Nominee</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Sit, perferendis!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={WinnerAward3}
                        alt="winner-award3"
                      />
                      <p className="award-yer">2023</p>
                    </div>
                    <p className="award-interior">EWA Design</p>
                    <p className="award-winner-text">Winner</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Sit, perferendis!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={WinnerAward4}
                        alt="winner-award4"
                      />
                      <p className="award-yer">2022</p>
                    </div>
                    <p className="award-interior">BEST Star</p>
                    <p className="award-winner-text">Winner</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Sit, perferendis!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- ====================================== Section Award End ===================================== --> */}
    </>
  );
};
export default Resume;
